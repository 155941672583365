// This configuration file is a single place to provide any values to set up the app

export const IDENTITY_POOL_ID =
  "ca-central-1:42487ca9-29ca-4b7e-b88b-ff5235cd05b6"; // REQUIRED - Amazon Cognito Identity Pool ID

export const REGION = "ca-central-1"; // REQUIRED - Amazon Cognito Region

export const MAP = {
  NAME: "RCMap1", // REQUIRED - Amazon Location Service map resource name
  STYLE: "Default style", // REQUIRED - String representing the style of map resource
};

export const PLACE = "XXXXXXXXXXX"; // REQUIRED - Amazon Location Service place index resource name
export const ROUTE = "XXXXXXXXXXX"; // REQUIRED - Amazon Location Service route calculator resource name
export const GEOFENCE = "XXXXXXXXXXX"; // REQUIRED - Amazon Location Service geofence collection resource name
export const TRACKER = "XXXXXXXXXXX"; // REQUIRED - Amazon Location Service tracker resource name
export const TRACKER_SIMULATED_DEVICE = "Vehicle-1"; // REQUIRED - Simulated Device ID (Defaulting to Vehicle-1)