

// API
import { API, graphqlOperation} from 'aws-amplify';

// GraphQL (NoSQL Database backend)
import { getPlaylist, listPlaylists } from '../graphql/queries';
import { createPlaylist, updatePlaylist, deletePlaylist } from '../graphql/mutations';


// Save the Search Term data for a country
export const saveSearchTermNew = (country, lang, search) => { 
    API.graphql(
        graphqlOperation(createPlaylist,
        {    input:
        {
            countrycode: country,
            language: lang,
            search_term: search
        }
        },
        {authMode: 'API_KEY'}
    ))
    .then((response) => console.log("inserted graphQL ", response))
    .catch((error) => console.log(error));
};

// Save the Search Term data for a country
// Insert or update
export const saveSearchTerm = async (country, lang, search) => { 

    // Look for existing data, if any
    let res = await loadCountryData(country, lang);

    // Check results
    let items = res.data.listPlaylists.items;
    // Find the first country-Playlist-record and return it (should only have one)
    if (items.length > 0){

        // If any matched, we update it with new term
        let currentID = items[0].id;
        updateCountryData(currentID, search)
        .then(()=> console.log("updated successful"))
        .catch((error)=> console.log("update error!"));

    } else {
        // Nothing matched - insert a new one.
        saveSearchTermNew(country, lang, search);
    }
};

export const updateCountryData = (countryID, search) => {
    return API.graphql(
        graphqlOperation(updatePlaylist,
        {    input:
        {
            id: countryID,
            search_term: search
        }
        },
        {authMode: 'API_KEY'}
    ));
}

// Get the Search Term data for a country
export const loadCountryData = (country, lang) => {
    return API.graphql(
        graphqlOperation(listPlaylists,
            {
                filter: {
                    countrycode: {eq: country}, 
                    language: {eq: lang}
                }
            }
            ));
};