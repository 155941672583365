
const mbzBaseURL = 'https://musicbrainz.org/ws/2/';

// This is an URL for retrieving 
export function queryPlacesbyLocation(lat, long, type){
    // Use a default for now, should be dynamic...
    let minlat = lat -0.3;
    let maxlat = lat +0.3;
    let minlong = long -0.6;
    let maxlong = long +0.6;

    // Generate
    let url = mbzBaseURL + 'place/?fmt=json&query=lat:%5B'
               + minlat + '%20TO%20' + maxlat + '%5D%20AND%20long:%5B' 
               + minlong + '%20TO%20' + maxlong + '%5D';
    
    // Lifespan?           %20AND%20life-span.ended:false';

    // Place Type
    url = url + '%20AND%20type:' + type

    // Limit
    url = url + '&limit=50'

    return url;
}

export function queryRecordingsonPlace(placeid){
    // Use a default for now, should be dynamic...
    let url = mbzBaseURL + 'place/' + placeid + '?inc=recording-rels&fmt=json';

    // get the URL
    return url;
}

export function queryPlacesforRecording(mbid){
    // Use a default for now, should be dynamic...
    let url = mbzBaseURL + 'recording/' + mbid + '?inc=place-rels&fmt=json';
    // get the URL
    return url;
}

export function queryRecordingsbyISRC(isrc){
    // Use a default for now, should be dynamic...
    let url = mbzBaseURL + 'isrc/' + isrc + '?inc=place-rels&fmt=json';

    // get the URL
    return url;
}

export function findEarliestRecording(jsonrec){

    // Get the recordings array
    let recordings = jsonrec.recordings;
    let mindate = new Date();
    let minrecord = '';

    // If no data? Too bad son.
    if (recordings.length === 0)
    {
        return 'NODATA';
    }    
    // If there's only one, then we don't care ...
    if (recordings.length === 1)
    {
        return recordings[0].id;
    }
    // Otherwise, let's loop through them
    for (let i in recordings){
        let currentdate = new Date(recordings[i]['first-release-date']); // We don't want to know what happens when there's bad data...
        console.log("evaluating date: ", recordings[i]['first-release-date']);
        if (currentdate < mindate){
            minrecord = recordings[i].id;
            mindate = currentdate;
        }
    }
    // The minimum ID would remain
    console.log("min date recording: ", minrecord);
    return minrecord;

}





