// Create Root
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {
  SpotifyGetAuthorize,
  SpotifyGetTokenFromAPI
} from './spot_login';
import {
  generateRandomString
} from "./utilities";

import { Amplify }  from "aws-amplify";
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

// Need to think about how to do this
const mydefaultstate = "Abcderfgahskd132"; // TODO: It would be nice if we can randomize this for each user.

//GET Params
const queryParams = new URLSearchParams(window.location.search);
const code = queryParams.get('code');
const state = queryParams.get('state');
const access_token = queryParams.get('access_token');
const refresh_token = queryParams.get('refresh_token');
const expiry_timestamp = queryParams.get('expiry_timestamp');

// React
const root = ReactDOM.createRoot(document.getElementById('root'));

if (access_token != null && refresh_token != null){
  // Timestamp
  console.log("The new Access Token will expire at ", expiry_timestamp, ", current Timestamp is: ", Math.floor(Date.now() / 1000));
  // We have the tokens, let's roll!
  root.render(
  <React.StrictMode>
  <App
    access_token={access_token}
    refresh_token={refresh_token}
    expiry_timestamp={expiry_timestamp}
  />
  </React.StrictMode>);

} else if (code != null && state != null && state === mydefaultstate){ 
  // We have the code and state, proceeding to next...
  console.log("Validated code and state ", code, state);
  // Serverside More secure
  SpotifyGetTokenFromAPI(code);

} else {
  // Build our query terms for Code and State
  console.log("We want to redirect to Spotify login");
  //document.cookie = "spotifystate = "+mydefaultstate;
  SpotifyGetAuthorize(mydefaultstate);
 
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
