
import { Buffer } from 'buffer';
import { API }  from "aws-amplify";

// Spotify login data
const client_id = '10d128cd5cc6463d913f753e63c3fcd3';
const redirect_uri = window.location.href.split("?")[0];
//const client_secret = // moved to server;

const spotifyAuthorizeURL = 'https://accounts.spotify.com/authorize?';
const spotifyTokenURL = 'https://accounts.spotify.com/api/token';

export function SpotifyGetAuthorize(state){
  /*
  Client calls this function to initiate the Spotify login process by redirecting to the Spotify authorize URL, 
  which will then re-direct back to the redirect_uri (origin)
  RC: I can't really move this step to a Server-side function, since it always ran into CORS issue? 
  */
    var scope = 'playlist-read-private playlist-read-collaborative user-read-private user-read-email';
    var querySpot = spotifyAuthorizeURL +
      new URLSearchParams({
        response_type: 'code',
        client_id: client_id,
        scope: scope,
        redirect_uri: redirect_uri,
        state: state,
        show_dialog: true
      }).toString();
    window.location.replace(querySpot);
}

export function SpotifyGetTokenFromAPI(code){
  /*
    Client calls this function to invoke our Lambda API (server side) that will 
    contact Spotify Token API to retrieve the token. This ensures that client secret is not exposed to the browser.
  */
  API.get('spotifylogin', "/spot/token/" + code + "/?" +
        new URLSearchParams({
          redirect_uri: redirect_uri
        }).toString())
      .then((data) => {
        //console.log("We got it right? ", data);
        var expiry_timestamp = Math.floor(Date.now() / 1000) + data.expires_in        
        var querySpot = "/?" +
        new URLSearchParams({
          access_token: data.access_token,
          expiry_timestamp: expiry_timestamp, // We'll just tell the browser when it'll expire.
          refresh_token: data.refresh_token
        }).toString();
        //console.log("Redirecting: ", querySpot);
        window.location.replace(querySpot);  // For now. We need to design a better way to keep these tokens!
        })
      .catch((error) => {
        console.log(error);
  });
}

export function SpotifyGetToken(code){
  /*
    Client calls this function to contact Spotify Token API to retrieve the token.
    This variant requires client_secret here, so it's not preferred.
  */
    // Spotify Access Token
    var formdata = new URLSearchParams({
        code: code,
        redirect_uri: redirect_uri,
        grant_type: 'authorization_code'}).toString();
  // Spotify uses a 'rarely used' form of POST using x-www-form-urlencoded format
  // Check documentation at https://javascript.info/fetch
  fetch(spotifyTokenURL, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic ' // + (new Buffer.from(client_id + ':' + client_secret).toString('base64'))
    },
    body: formdata }
    )
   .then((response) => 
    response.json())
   .then((data) => {
        var expiry_timestamp = Math.floor(Date.now() / 1000) + data.expires_in        
        var querySpot = "/?" +
        new URLSearchParams({
            access_token: data.access_token,
            expiry_timestamp: expiry_timestamp, // We'll just tell the browser when it'll expire.
            refresh_token: data.refresh_token
        }).toString();
        //console.log("new spot: ", querySpot);
        window.location.replace(querySpot);
     })
   .catch(error => {
     console.log(error)
   });
}

export function SpotifyRefreshTokenFromAPI(refresh_token){
  /*
    Request a refresh token and refresh our window to it. (Crude)
  */
  API.get('spotifylogin', "/spot/refresh/" + refresh_token)
      .then((data) => {
        //console.log("We got it right? ", data);
        var expiry_timestamp = Math.floor(Date.now() / 1000) + data.expires_in        
        var querySpot = "/?" +
        new URLSearchParams({
          access_token: data.access_token,
          expiry_timestamp: expiry_timestamp, // We'll just tell the browser when it'll expire.
          refresh_token: data.refresh_token
        }).toString();
        //console.log("Redirecting: ", querySpot);
        window.location.replace(querySpot);  // For now. We need to design a better way to keep these tokens!
        })
      .catch((error) => {
        console.log(error);
  });
}

export async function SpotifySearchPlaylist(querystring, access_token) {
    
    // TODO Get our Timestamp and expiry to refresh!
    let url = "https://api.spotify.com/v1/search?";
    let q = new URLSearchParams(
      {'q': querystring,
      'type': 'playlist'    
    }).toString();
    let headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + access_token
    }
    //console.log("Spotify API request: ", url + q);
    //console.log(headers);

    let resdata = await fetch(url + q, {headers: headers})
        .then((response) => response.json())
        .then((data) => {
            //console.log("We got data: ", data);
            return data.playlists.items;
        })
        .catch(error => {
        console.log(error)
        });
    
    return resdata;

}

export async function SpotifyGetPlaylist(playlist_id, access_token) {
    
  // TODO Get our Timestamp and expiry to refresh!
  let url = "https://api.spotify.com/v1/playlists/" + playlist_id + "?";
  let q = new URLSearchParams(
    {'fields': 'tracks.items(track(href,id,name,album.images,popularity,preview_url))'
  }).toString();
  let headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + access_token
  }
  let resdata = await fetch(url + q, {headers: headers})
      .then((response) => response.json())
      .then((data) => {
          console.log("We got data: ", data);
          return data;
      })
      .catch(error => {
      console.log(error)
      });
  return resdata;
}


export async function GetSpotifyTracks(playlist_id, access_token){
  // Verify
  if (playlist_id != null ){
    // Get the playlist
    let thisplay = await SpotifyGetPlaylist(playlist_id, access_token);
    // Return the tracks
    return thisplay.tracks.items;
  }
}

export async function GetSpotifyTrack1FromPlaylist(playlist_id, access_token){

  // Verify
  if (playlist_id != null ){

  // Get the playlist
  let thisplay = await SpotifyGetPlaylist(playlist_id, access_token);

  // find a Track
  console.log("Got the list: ", thisplay);

  var onetrack;
  for(let x in thisplay.tracks.items){
    console.log("checking ", thisplay.tracks.items[x]);
    if(thisplay.tracks.items[x].track.preview_url){
      console.log("Taking track: ", thisplay.tracks.items[x].track.preview_url)
      onetrack = thisplay.tracks.items[x].track;
      break;
    }
  }
  //return onetrack;
  
  /* Play the Audio 
  console.log("Try to play ", onetrack);
  var aud = new Audio(onetrack); 
  aud.crossOrigin="anonymous"; 
  aud.play().then(console.log("played!")).catch(error => {
    console.log(error)}
    )
*/
    return onetrack;
    
  }

}
