import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Amplify, API, Auth, graphqlOperation} from 'aws-amplify';
import { LocationClient } from "@aws-sdk/client-location";
import { 
  queryPlacesbyLocation,
  queryRecordingsonPlace,
  queryRecordingsbyISRC,
  findEarliestRecording
  } from "./musicbrainzURL";
import {
  SpotifyRefreshTokenFromAPI,
  SpotifySearchPlaylist,
  GetSpotifyTrack1FromPlaylist
} from "./spot_login";


import { IDENTITY_POOL_ID, REGION, MAP, PLACE } from "./configuration";
import {
  MAP_CONTAINER,
} from "./constants";

// ***** React Components *****
import Navbar from './navbar.js';
import './App.css';
// MapView 
import { ThemeProvider, MapView  } from "@aws-amplify/ui-react";

import { theme } from "./theme";
import '@aws-amplify/ui-react/styles.css';
// Marker With Popup
import { MarkerWithPopup } from "./components/marker_with_popup";
import { CountryMarkerPops } from "./components/country_marker_popups";
import { saveSearchTermNew, saveSearchTerm, loadCountryData } from './components/countryPlaylistDB';

// MapLibre implementation
//import { createMap } from "maplibre-gl-js-amplify";
import "maplibre-gl/dist/maplibre-gl.css";
import "maplibre-gl-js-amplify/dist/public/amplify-map.css";

// Fixing the Uncaught ReferenceError y is not defined
import mapboxgl from "mapbox-gl"; // This is a dependency of react-map-gl even if you didn't explicitly install it
import "./index.css";

//
// ***** importing Music data from files *****
//
import countriesData from './data/countries.json';
import global50stat from './data/spotify_global_50_clean.json';
// 
//
//

// Amplify Auth - export
import awsExports from './aws-exports';

// Add SSR Support before GraphQL keep throwing Errors?
Amplify.configure({...awsExports, ssr: true});

// Amplify - configure our necessary credentials
Amplify.configure({
  Auth: {
    identityPoolId: "ca-central-1:42487ca9-29ca-4b7e-b88b-ff5235cd05b6",
    region: "ca-central-1",
  },
  geo: {
    AmazonLocationService: {
      maps: {
        items: {
          "RCMap1": {
            style: "Default style" // ESRI Dark Grey canvas?
          },
        },
        default: "RCMap1",
      },
      region: "ca-central-1",
    },
  }

});

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;


// Let's get some real serious data to test out
const countriesMarkers = countriesData.map((k) => (
  {
    id: k.id,
    countrycode: k.code3l,
    title: k.name,
    description: k.name_official,
    image: 'https://amplify-raymap-userdata.s3.ca-central-1.amazonaws.com/flags/PNG-32/'+k.code2l+'-32.png',
    bigimage: 'https://amplify-raymap-userdata.s3.ca-central-1.amazonaws.com/flags/PNG-128/'+k.code2l+'-128.png',
    latitude: k.center.latitude,
    longitude: k.center.longitude
}))

// That's for the REST API part I guess
const myAPI = 'rcmapAPI';
const path = '/timeline';
// MusicBrainz Agent header information
const mbzUserAgent = `rc-musicmap-app/0.1.0 ( raychien192@gmail.com )`;

/*
  MapView is fully integrated with the open source library react-map-gl v7 while using maplibre-gl-js as the map tile source. 
  MapView is used as a replacement to react-map-gl's default map and supports the same functionality.
*/
const App = (app_props) => {
  /*
  Arguments: (Spotify session token)
    access_token
    refresh_token
    expiry_timestamp
  */

  // Responses to actions ...
  const [credentials, setCredentials] = useState();
  const [client, setClient] = useState();
  const [placeType, setPlacetype] = useState('Studio');  
  const [input, setInput] = useState("");
  const [musicmaps, setMusicMap] = useState([]);
  const [top50sresponse, setTop50sresponse] = useState();
  const [Top50sData, setTop50sData] = useState([]);
  const [spotifyQ, setSpotifyQ] = useState();
  const [spotifyData, setSpotifyData] = useState([]);
  const [spotPreview, setSpotPreview] = useState();
  const [activeMarker, setActiveMarker] = useState();
  const [mapLocations, setMapLocations] = useState([]);
  // Reactive view state
  const [viewState, setViewState] = React.useState({
    //Singapore
    latitude: 1.33873261,
    longitude: 103.83323559,
    zoom: 4
  })
  // Update when needed  
  const [musicBrainzQuery, setMusicBrainzQuery] = React.useState();
  const [musicLocations, setMusicLocations] = React.useState([]);

  // Use this for Map animations
  // Documentation: https://ui.docs.amplify.aws/react/connected-components/geo
  const mapRef = useRef();

  // For Marker Refresh
  const GetActiveMarker = () => {
    return activeMarker;
  };

  // This Marker wants to take focus
  const MovetoMarker = useCallback((newlat, newlong, newID) => {

    // Set the Active Marker (as the ID) This is automatically sent to children components. 
    // Each children component which isn't of the same ID, will close itself.
    setActiveMarker(newID);

    // We might need to do something about the ViewState... or do we? TODO.
    let newViewState = {...viewState};
    console.log("New marker is: ", newID);

    //newViewState.latitude = newlat;
    //newViewState.longitude = newlong;

    // This is not a Then-able function, so we will probably want to set ViewState after N seconds.
    // But doing so, users might just decide to do their own thing with the map.
    // But then again, wouldn't that just completes the update?
    mapRef.current.flyTo({ 
      center: [newlong, newlat], 
      zoom: viewState.zoom,
      speed: 0.2      
    });
    //setViewState(newViewState);
  });

  // fetches a new mbzQuery when the musicBrainzQuery State changed
  useEffect(() => {
       fetch(musicBrainzQuery, 
        {
          'Content-Type': 'application/json',
          'User-Agent': mbzUserAgent
        }
        )
      .then((response) => response.json())
      .then((data) => {
         console.log("we got data, really: ", data);
         setMusicLocations(data.places);
        })
      .catch(error => {
        console.log(error)
      });

    }, [musicBrainzQuery]);

    /* Use static list for now (to avoid the "Allow Cross origin" error)
    */
    function LoadTop50 ()
    {
      if (global50stat){

      let myItems = global50stat.items;
      // The "x" is actually returning the keys to elements ... that's what's wrong with my query all along.
      for (let x in myItems){
        console.log("x: ", x);

        setTimeout(function (x) {

          console.log("ISRCs: ", JSON.stringify(myItems[x].isrc));
          // Get the MusicBrainz query for this track
          fetch(queryRecordingsbyISRC(myItems[x].isrc), 
          {
            'Content-Type': 'application/json',
            'User-Agent': mbzUserAgent
          }
          )
        .then((response) => response.json())
        .then((data) => {

          // Try to find the best matching Recording (I'm going to guess Earliest recording is best)
          let recid = findEarliestRecording(data);

          // Then we'll find the corresponding ...

           let UpdatedTop50sData = [...Top50sData];
           console.log("we got track: ", data);
           UpdatedTop50sData.push(data);
           setTop50sData(UpdatedTop50sData);
          })
        .catch(error => {
          console.log(error)
        });

        }, 1000 * x, x)
   
      }
    }
    }

  /* When we receive the Top 50 data, iterate through each element and list:
    - Track title 
    - ISRC
    - Artist info
    Let's just for the record and say Spotify data is obscenely difficult to use!
  */
  useEffect(() => {
    if (top50sresponse){
    //console.log(top50sresponse);
    let myTracks = top50sresponse.tracks;
    //console.log(JSON.stringify(myTracks));
    let myItems = myTracks.items;
    let NewItems = myItems.map(function (data) {
      console.log(data);
      return {
        'name': data.track.name,
        'isrc': data.track.external_ids,
      };
      }
    );
    console.log(NewItems);

    // The "let" is actually returning the keys to elements ... that's what's wrong with my query all along.
    for (let x in NewItems){

      console.log("ISRCs: ", JSON.stringify(NewItems[x].isrc.isrc));
      // Get the MusicBrainz query for this track
      fetch(queryRecordingsbyISRC(NewItems[x].isrc.isrc), 
      {
        'mode': 'cors',
        'Content-Type': 'application/json',
        'User-Agent': mbzUserAgent
      }
      )
    .then((response) => response.json())
    .then((data) => {
       let UpdatedTop50sData = [...Top50sData];
       console.log("we got track: ", data);
       UpdatedTop50sData.push(data);
       setTop50sData(UpdatedTop50sData);
      })
    .catch(error => {
      console.log(error)
    });

    }
  }
  }, [top50sresponse]);

  // Functions to pull data from API...
  function GetMusicBrainzData(){
    // We don't have a box yet, so let's improvise with a small area search ...
    let lat = viewState.latitude;
    let long = viewState.longitude;
    let placetype = placeType;
    let url = queryPlacesbyLocation(lat, long, placetype);
    console.log(url);
    setMusicBrainzQuery(url);
  }

  //Function to fetch from our backend and update customers array
  function getMusicMap(e) {
    let targetdate = e.input
    API.get(myAPI, path + "/" + targetdate)
       .then(response => {
         console.log(response)
         let newMusicMaps = [...musicmaps]
         newMusicMaps.push(response)
         setMusicMap(newMusicMaps)

       })
       .catch(error => {
         console.log(error)
       })
  }

  // Using Text input box as search term, find any Spotify playlist that have matching terms
  function do_Spotify_search() {
    // Will probably mean a reload and won't proceed with the search ...
    if (spotExpired()){
      SpotifyRefreshTokenFromAPI(app_props.refresh_token);
    }
    SpotifySearchPlaylist(spotifyQ, app_props.access_token)
    .then((data)=> setSpotifyData(data));
  }

  // Update the current embedded player
  const UpdateAndPlay = (tracks) => {
    var track;
    for(let x in tracks){
      console.log("checking ", tracks[x]);
      if(tracks[x].track.preview_url){
        console.log("Taking track: ", tracks[x].track.preview_url)
        track = tracks[x].track;
        break;
      }
    }
    console.log("Updating spotify data ", tracks);
    setSpotifyData(tracks);
    loadSpotifyPlayer(track);
  }

  // Just load player
  const loadSpotifyPlayer = (track) => {
    console.log("playing track ", track);
    setSpotPreview(track.name);
    // Doublecheck
    if (track.preview_url){
      let spotImg = document.getElementById('spot_prev_img');
      spotImg.src = track.album.images[0].url;
      let spotAudio = document.getElementById('spot_previewer');
      spotAudio.pause();
      spotAudio.src = track.preview_url;
      spotAudio.load();
      spotAudio.crossOrigin="anonymous"; 
      spotAudio.play().then(console.log("played!")).catch(error => {
        console.log(error)}
        )
    }
  }

  //Fetch credentials when the app loads
  useEffect(() => {
    const fetchCredentials = async () => {
      // Fetch AWS credentials from Amazon Cognito using Amplify Auth and storing it in state
      setCredentials(await Auth.currentUserCredentials());
    };
    fetchCredentials();
  }, []);

  // Instantiate client for aws-sdk whenever the credentials change
  useEffect(() => {
    if (credentials != null) {
      // Instantiate client for aws-sdk method calls
      const client = new LocationClient({
        credentials,
        region: REGION,
      });

      setClient(client);
    }
  }, [credentials]);

  // Follows here the actual ouptut to App page...
  /* Use later...
          <p>
          <select id="placetype" onChange={(e) => setPlacetype(e.target.value)}>
            <option value="Studio">Studio</option>
            <option value="Venue">Venue</option>
            <option value="Stadium">Stadium</option>
          </select>
          <button onClick={() => GetMusicBrainzData()}>Get MusicBrainz Locations on View</button><br/>
        </p>
        */
  return (
    <ThemeProvider theme={theme}>
        <Navbar/>
        <div className="spotifyresults">
          <button onClick={() => SpotifyRefreshTokenFromAPI(app_props.refresh_token)}>Manual Refresh Token</button>
          <p>
          { spotPreview ? ('Now playing:' + spotPreview) : 'No audio loaded'}<br></br>
          <img id="spot_prev_img" width="100" height="100"></img>
          <audio id="spot_previewer" controls></audio><br></br>
          { // display all of the tracks currently loaded
            spotifyData.map((d)=> (
              <div><a href="#" onClick={() => loadSpotifyPlayer(d.track)}>{d.track.name}</a> {d.track.popularity}
              </div>
            ))
          }          
          </p>
        </div>

      {client ? (
        <MapView
          ref={mapRef}
          id={MAP_CONTAINER}
          //width="100%"
          //height="100vh"
          {...viewState}
          mapStyle={MAP.NAME}
          onMove={
            e => setViewState(e.viewState)
                      }
          maxZoom={16}
        >
        {
         countriesMarkers.map((loc) => ( // Use Flag data from Git...
              <CountryMarkerPops
                cred={app_props}
                key={loc.id}
                id={loc.id}
                latitude={loc.latitude}
                longitude={loc.longitude}
                countrycode={loc.countrycode}
                title={loc.title}
                image={loc.image}
                updateAndPlay = {UpdateAndPlay}
                takeFocus = {MovetoMarker}
                getFocus = {GetActiveMarker}
                activeMarker = {activeMarker}
                />
          ))
          }

        </MapView>
      ) : (
        <h1>Loading...</h1>
      )
      }
    </ThemeProvider>
      
  )


  function spotExpired () {
    /* Check if expired and return */
    return (app_props.expiry_timestamp >= Math.floor(Date.now() / 1000)) ? true : false
  }

}

export default App;
