import React, { useEffect, useRef, useState } from 'react';
import { Amplify, API, Auth, graphqlOperation} from 'aws-amplify';
import { Marker, Popup } from "react-map-gl";
// MapView 
import { Flex, Heading, View, Text, Image
    } from "@aws-amplify/ui-react";
import {
      SpotifyRefreshTokenFromAPI,
      SpotifySearchPlaylist,
      GetSpotifyTracks,
      GetSpotifyTrack1FromPlaylist
    } from "../spot_login";

// GraphQL (NoSQL Database backend)
import { getPlaylist, listPlaylists } from '../graphql/queries';
import { createPlaylist, updatePlaylist, deletePlaylist } from '../graphql/mutations';
import { saveSearchTerm, loadCountryData } from '../components/countryPlaylistDB';


// Marker with Popup COMPONENT
/* 
    The marker is a country flag (for now)
    The popup displayed when user clicks on a flag icon on the map.
    The popup will include:
      Search term (which is either generated, or from the DB Backend)
      Top 4 Playlists returned after auto-searching by the Search term

    The user may:
      - Edit the Search term and refresh search
      - Click on a Playlist icon and play a preview track, as well as show listing of all tracks on a side

*/
export function CountryMarkerPops({ cred, id, latitude, longitude, countrycode, title, image, updateAndPlay, takeFocus, getFocus, activeMarker }) {
  const [showPopup, setShowPopup] = useState(false);
  const [playlists, setPlaylists] = useState([]);
  const [currentPlaylistID, setCurrentPlaylistID] = useState();
  const [currentSearch, setCurrentSearch] = useState(title + ' Top 50');

  // When parent activeMarker changed, we'll refresh our Popup...
  useEffect(() => {
      setShowPopup(activeMarker === id);

  }, [activeMarker])

  async function GetSavedPlaylistConfig(country){
    // Language is always English for now
    const lang = 'EN';
    let search_term = title + ' Top 50';
    // Find target country records in English
    console.log("Looking for country record for ", country);

    // Get the country data search term and playlist
    let res = await loadCountryData(country, lang);

    // A very nested result...
    let items = res.data.listPlaylists.items;
    // Find the first country-Playlist-record and return it (should only have one)
      if (items.length > 0){
        // Use the result for search
        let countryRec = items[0];
        setCurrentPlaylistID(countryRec.id);
        search_term = countryRec.search_term;
        setCurrentSearch(search_term);
        console.log("We found saved Playlist ", countryRec);

      } else {
        // No records found - use default
        console.log("No saved playlist");
      }        
      // now do the search
      LoadOnePlaylist(search_term);
      
  }

  function updateAndRefresh(search_term){
    
    // Create, or update, our search term
    saveSearchTerm(countrycode, "EN", search_term);
    
    // Then load the playlists
    LoadOnePlaylist(search_term, cred.access_token);

  }

  // Functions to pull data from API...
  function LoadOnePlaylist(search_term){

    // Do a spotify search for Country Name + ' Top 50'
    //let search_term = title + ' Top 50';

    // Search for playlists, put the first one in our playlist slot.
    SpotifySearchPlaylist(search_term, cred.access_token)
    .then((data)=> {
        let newdata = data.slice(0,4);
        console.log(newdata);
        //setpopImage(data[0].images[0].url);
        setPlaylists(newdata);
        console.log("done setting playlists");
      } )
    .then(setShowPopup(true));
  }

  const handleMarkerClick = ({ originalEvent }) => {
    originalEvent.stopPropagation()

    // Map will gain focus
    takeFocus(latitude, longitude, id);
    GetSavedPlaylistConfig(countrycode);
  }

  return (
    <>
      <Marker
        key={id}
        latitude={latitude}
        longitude={longitude}
        onClick={handleMarkerClick}
        scale={0.8}
        element={document.getElementById(id)}
        >
          <img src={image}></img>
      </Marker>
      {showPopup && playlists && ( // Horrible hack but it seems to work ...
        <Popup
          key={id + 'popup'}
          latitude={latitude}
          longitude={longitude}
          offset={{ bottom: [0, -40] }}
          onClose={() => setShowPopup(false)}
        >
          <input type="text" value={currentSearch} onChange={(e) => setCurrentSearch(e.target.value)}/>
          <button onClick={() => updateAndRefresh(currentSearch)}>Refresh</button>
          {
          playlists.map((pl) => (
            <div className="content_img" key={pl.id}>
              <img src={pl.images[0].url} width="100" height="100" onClick={
                  () => GetSpotifyTracks(pl.id, cred.access_token)
                          .then(
                            (tracks)=> {
                              updateAndPlay(tracks);

                            } ) } />
              <div key={pl.id}>{pl.tracks.total} tracks<br/>
              By {pl.owner.display_name}</div>
            </div>

          ))

          }
        </Popup>
      )}
    </>
  )
}
